table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

th {
  background: white;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
